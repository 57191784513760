.eye-icon {
  font-size: 1.2em !important;
  margin-right: 0.5rem !important;
  cursor: pointer;
}

.edit-icon {
  font-size: 1.2em !important;
  cursor: pointer !important;
  margin-right: 0.5rem !important;
}

.delete-icon {
  font-size: 1em !important;
  cursor: pointer !important;
}

.file-icon {
  cursor: pointer;
  font-size: 1.5em;
  color: black;
  margin: 0 0.5rem;
}
