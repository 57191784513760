#userActivityLog {
  th {
    text-align: center;
    vertical-align: middle;
  }

  td {
    padding: 0.5rem;
    text-align: center;
    vertical-align: middle;

    &:nth-child(5),
    &:nth-child(6) {
      text-align: start;
    }
  }
}
