#universityForm {
  .col-md-3 {
    margin-bottom: 1rem;
  }
}

#universityTable {
  th {
    text-align: center;
    vertical-align: middle;
  }

  td {
    padding: 0.5rem;
    text-align: start;
    vertical-align: middle;

    &:nth-child(1),
    &:nth-child(2),
    &:nth-child(6),
    &:nth-child(7),
    &:nth-child(9) {
      text-align: center;
    }

    .edit-icon {
      font-size: 1.2em !important;
      cursor: pointer !important;
      margin: 0 0.5rem !important;
    }

    .delete-icon {
      font-size: 1em !important;
      cursor: pointer !important;
    }
  }
}
