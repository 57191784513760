.base-TablePagination-toolbar {
  padding: 1rem;
  min-width: 100% !important;
  display: flex !important;
  -webkit-flex-direction: column !important;
  -ms-flex-direction: column !important;
  flex-direction: row !important;
  -webkit-align-items: space-around !important;
  -webkit-box-align: space-around !important;
  -ms-flex-align: space-around !important;
  align-items: center !important;
  justify-content: space-around !important;
  background-color: #fff !important;
  flex-wrap: wrap;
}

#paginationComponent .MuiPagination-ul {
  display: flex !important;
  justify-content: center !important;
}
