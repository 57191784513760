#collegeTable {
  th {
    text-align: center;
    vertical-align: middle;
  }

  td {
    padding: 0.5rem;
    text-align: start;
    vertical-align: middle;

    &:nth-child(1),
    &:nth-child(2),
    &:nth-child(6),
    &:nth-child(9) {
      text-align: center;
    }
  }
}
